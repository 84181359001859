import { StepConditionStrategy } from '@components/ProductTour/Shared/StepConditionStrategy'

export class StepTwoCondition extends StepConditionStrategy {
  constructor(step: number, condition: boolean) {
    super()
    const STEP_TWO = 2

    this.id = STEP_TWO
    this.step = step
    this.condition = condition
  }
}
