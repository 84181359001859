import useCancelAutoRejectOffer from '@hooks/useCancelAutoRejectOffer'
import { Button, Modal, Text } from '@nextui-org/react'
import { Form, Formik } from 'formik'
import { useIntl } from 'react-intl'

export default function AskCancelAutoRejectOfferModal() {
  const { formatMessage: f } = useIntl()
  const { isOpen, offerId, submitHandler, closeHandler, autoCloseHandler } =
    useCancelAutoRejectOffer()

  /*
   TODO: Requirements for AskCancelAutoRejectOfferModal 
   - Open with the signalR
   - Button to cancel the auto reject feature
   - Auto close in 30s
  */

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        blur
        closeButton
        // fullScreen // TODO: mobile debvices style
        open={isOpen}
        onClose={closeHandler}
        preventClose
      >
        <Formik initialValues={{}} onSubmit={submitHandler}>
          {(formik) => (
            <Form className="w-full px-2 py-4">
              <Modal.Header>
                <Text className="font-bold text-xl">
                  {f({ id: 'Avilability to start an offer ' })}
                </Text>
              </Modal.Header>
              <Modal.Body>
                <div className="w-full">
                  <Text className="mb-2">
                    {f({
                      id: 'You have an offer that start in 50 minutes, do you want proceed with it?'
                    })}
                  </Text>
                  <Text className="opacity-40">
                    {f({
                      id: 'If you press cancel, your offer will be canceled automatically, without penalty risk.'
                    })}
                  </Text>
                </div>
              </Modal.Body>
              <Modal.Footer className="mt-12">
                <Button type="submit" auto>
                  {f({ id: 'Proceed' })}
                </Button>
                <Button onPress={closeHandler} type="button" bordered auto>
                  {f({ id: 'Cancel' })}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
