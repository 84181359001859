import useAuthChallenge from '@hooks/useAuthChallenge'
import { VerificationCode } from '@models/dtos/VerificationCode'
import { Link, Modal, Text } from '@nextui-org/react'

import { ChallengeType } from '@models/AuthChallenge'
import { useAppSelector } from '@store/hooks'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import BInput from '../BInput'
import BButton from '../bButton/BButton'

export default function VerificationCodeModal() {
  const { formatMessage: f } = useIntl()

  const { isVerifyingOtp, isResendAvilable, message, submit, resend } =
    useAuthChallenge()
  const { session } = useAppSelector((state) => state.auth)

  const [otp, setOtp] = useState('')

  const initialValues: VerificationCode = {
    otpCode: ''
  }

  const formSchema = yup.object({
    otpCode: yup
      .string()
      .matches(/([0-9])/, f({ id: 'OTP code could only be numbers' }))
      .length(6, f({ id: 'OTP code must have {len} characters long' }))
      .required(f({ id: 'OTP code is required' }))
  })

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        blur
        // fullScreen // TODO: mobile devices style
        open={
          Boolean(message.isOpen) &&
          (message.challengeType === ChallengeType.AUTH ||
            message.challengeType === ChallengeType.ALL)
        }
        // open={Boolean(true)}
        preventClose
      >
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={submit}
        >
          {(formik) => (
            <Form className="w-full px-2 py-4">
              {/* <Modal.Header>
                <Text className="font-bold text-xl">
                  {f({ id: 'Enter verification code' })}
                </Text>
              </Modal.Header> */}
              <Modal.Body>
                <Text className="font-bold text-xl text-center">
                  {f({ id: 'Enter verification code' })}
                </Text>
                <Text className="text-center mb-8 opacity-40">
                  {f({ id: 'Amazon Flex sent a verification code to ' })}
                  {session.email}
                </Text>
                <BInput
                  disabled={isVerifyingOtp}
                  label={f({ id: 'Security Code' })}
                  value={otp}
                  onChange={formik.handleChange('otpCode')}
                  error={formik.errors.otpCode}
                  // contentLeft={blocksIcons.unlock}
                  type="text"
                  // size="lg"
                  onlyNumbers
                  bordered
                  clearable
                />
                <BButton
                  disabled={isVerifyingOtp}
                  className="mt-4 w-full"
                  isLoading={isResendAvilable === false}
                  onClick={resend}
                  text={f({
                    id: 'Resend code again'
                  })}
                  bordered
                  type="button"
                />
                <div className="w-full flex justify-center">
                  <Link block className="">
                    {f({ id: 'Do you need help?' })}
                  </Link>
                </div>
              </Modal.Body>
              <Modal.Footer className="flex flex-col mt-12">
                <BButton
                  isLoading={isVerifyingOtp}
                  text={f({
                    id: 'Submit code'
                  })}
                  type="submit"
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
