import { useSignalRWsSecure } from '@context/signalr/SignalRWsSecureContext'
import { CancelAutoRejectModel } from '@models/CancelAutoRejectModel'
import { useAppDispatch } from '@store/hooks'
import { cancelAutoRejectOfferRequest } from '@store/offers/cancelAutoRejectOffer.slice'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'

export interface UseCancelAutoRejectOfferReturnType {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  offerId: string
  submitHandler: () => Promise<void>
  closeHandler: () => Promise<void>
  autoCloseHandler: () => Promise<void>
}

export default function useCancelAutoRejectOffer(): UseCancelAutoRejectOfferReturnType {
  const dispatch = useAppDispatch()
  const hubConnectionContext = useSignalRWsSecure()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [offerId, setOfferId] = useState<string>()

  const submitHandler = async (): Promise<void> => {
    const data: CancelAutoRejectModel = { offerId }
    dispatch(cancelAutoRejectOfferRequest(data))
    setIsOpen(false)
  }
  const closeHandler = async (): Promise<void> => {
    setIsOpen(false)
  }
  const autoCloseHandler = async (): Promise<void> => {
    console.log('auto close modal')
    setIsOpen(false)
  }

  const onAskCancelAutoRejectOffer = useCallback(() => {
    hubConnectionContext?.on(
      'AskCancelAutoRejectOffer',
      (connectionId: string, offerId: string) => {
        setOfferId(offerId)
        setIsOpen(offerId !== undefined)
      }
    )
  }, [hubConnectionContext])

  useEffect(() => {
    onAskCancelAutoRejectOffer()
  }, [onAskCancelAutoRejectOffer])

  return {
    isOpen,
    setIsOpen,
    offerId,
    submitHandler,
    closeHandler,
    autoCloseHandler
  }
}
