import { ClassNames } from '@shared/DataValidation'
import { MutableRefObject, useEffect, useRef } from 'react'

export default function MenuToggleButton({
  setRef,
  className,
  colorCls = 'bg-white',
  onClick,
  isCollapsed
}: {
  setRef?: (ref: MutableRefObject<unknown>) => void
  className?: string
  colorCls?: string
  onClick?: (e: any) => void
  isCollapsed: boolean
}) {
  const openCls = !isCollapsed ? 'open' : ''
  const refButton = useRef()

  useEffect(() => {
    if (setRef !== undefined) setRef(refButton)
  }, [setRef, refButton])

  return (
    <button
      ref={refButton}
      className={ClassNames('menu-toggle-button', openCls, className)}
      onClick={onClick}
    >
      <span className={colorCls}></span>
      <span className={colorCls}></span>
      <span className={colorCls}></span>
    </button>
  )
}
