import { ClassNames } from 'shared'
import styles from './statusNotification.module.css'

export default function StatusNotification({
  className,
  children,
  isAlert
}: {
  className?: string
  children?: React.ReactNode
  isAlert?: boolean
}) {
  const color = isAlert === true ? 'bg-red-100' : 'bg-green-300'

  return (
    <span
      className={ClassNames(
        className,
        `before:${color} before:opacity-40`,
        styles.circleIcon
      )}
    >
      <span className={color}></span>
      {children}
    </span>
  )
}
