import { MoonIcon, SunIcon } from '@heroicons/react/20/solid'
import { useMantineColorScheme } from '@mantine/core'
import { Switch, useTheme } from '@nextui-org/react'
import { useTheme as useNextTheme } from 'next-themes'

export default function ChangeTheme({ className }: { className?: string }) {
  const { setTheme } = useNextTheme()
  const { toggleColorScheme } = useMantineColorScheme()
  const { isDark } = useTheme()

  const changeCls = (e) => {
    const theme = e.target.checked === true ? 'dark' : 'light'
    setTheme(theme)
    toggleColorScheme(theme)

    const htmlEl = document.getElementsByTagName('html')[0]
    const hasDark = htmlEl.className.includes('dark')

    if (hasDark) {
      htmlEl.classList.remove('dark')
      htmlEl.classList.add('light')
      return
    }

    htmlEl.classList.remove('light')
    htmlEl.classList.add('dark')
  }

  return (
    <div className={className}>
      <Switch
        checked={isDark}
        size="md"
        onChange={changeCls}
        iconOn={<MoonIcon />}
        iconOff={<SunIcon />}
      />
    </div>
  )
}
