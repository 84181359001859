import { CancelAutoRejectModel } from '@models/CancelAutoRejectModel'
import { Session } from '@models/Session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cancelAutoRejectOfferService } from '@services/offers/cancelAutoRejectOffer.service'
import { GetStorage } from '@shared/StorageHandler'

interface AutoRejectState {
  isOpen: boolean
  isLoading: boolean
  error: any | undefined
}

const initialState: AutoRejectState = {
  isOpen: false,
  isLoading: false,
  error: undefined
}

export const cancelAutoRejectOfferRequest = createAsyncThunk(
  'autoReject/cancelAutoRejectOfferRequest',
  async (data: CancelAutoRejectModel, { rejectWithValue }) => {
    try {
      const session = GetStorage<Session>('session') as Session
      return cancelAutoRejectOfferService(session.token, data)
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const autoReject = createSlice({
  name: 'autoReject',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cancelAutoRejectOfferRequest.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(cancelAutoRejectOfferRequest.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(
      cancelAutoRejectOfferRequest.rejected,
      (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      }
    )
  }
})
