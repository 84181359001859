import { flagEn, flagEs } from '@constants/images'
import { useLocale } from '@context/CustomIntlContext'
import { useStorage } from '@hooks/useStorage'
import { Language } from '@models/Settings'
import { Dropdown } from '@nextui-org/react'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { languagesRequest } from '@store/languages/languages.slice'
import { changeLanguageRequest } from '@store/settings/settings.slice'
import Image from 'next/image'
import { useEffect } from 'react'

export default function ChangeLang({ className }: { className?: string }) {
  const flags = {
    en: flagEn,
    es: flagEs
  }

  const dispatch = useAppDispatch()
  const [storedValue] = useStorage<string>('lang')
  const [locale, setLocale] = useLocale()
  const { languages } = useAppSelector((state) => state.languages)
  const { settings } = useAppSelector((state) => state.settings)

  const findLang = (listLang: Language[], abbr: string) => {
    return listLang?.find((x) => x.abbreviation === abbr)
  }

  const handleSelection = (lang: string) => {
    dispatch(changeLanguageRequest(lang))
    setLocale(lang)
  }

  const langFlagWrapper = (lang: Language) => (
    <div className="flex items-center">
      <Image
        className="mr-2"
        src={flags[lang?.abbreviation]}
        alt={'picture of country flag'}
        width="24"
      />
      <p>{lang?.name}</p>
    </div>
  )

  useEffect(() => {
    if (languages === undefined) dispatch(languagesRequest())
  }, [dispatch, languages])

  useEffect(() => {
    if (settings === undefined) return

    if (storedValue === '') {
      setLocale(settings.profileSetting.language.abbreviation)
    }
  }, [setLocale, settings])

  return (
    <div className={className}>
      <Dropdown>
        <Dropdown.Button color="default" light>
          {langFlagWrapper(findLang(languages, locale))}
        </Dropdown.Button>
        <Dropdown.Menu
          onAction={async (e) => {
            console.log('select:', e)
            handleSelection(String(e))
          }}
          color="default"
          variant="light"
          aria-label="Actions"
        >
          {languages?.map((lang) => (
            <Dropdown.Item key={lang.abbreviation} textValue={lang.name}>
              {langFlagWrapper(lang)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
