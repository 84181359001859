import CaptchaChallengeModal from './CaptchaChallengeModal'
import VerificationCodeModal from './VerificationCodeModal'

export default function AuthChallengesModals() {
  return (
    <>
      <VerificationCodeModal />
      <CaptchaChallengeModal />
    </>
  )
}
