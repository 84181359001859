import { authProviderRequest } from '@store/auth/authProvider.slice'
import { useAppDispatch } from '@store/hooks'

export interface UseAuthProviderType {
  authenticate: () => void
}

export default function useAuthProviderOnly(): UseAuthProviderType {
  const dispatch = useAppDispatch()

  const authenticate = () => {
    dispatch(authProviderRequest())
  }

  return { authenticate }
}
