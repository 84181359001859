import BButton from '@components/bButton/BButton'
import { secureLogin } from '@constants/images'
import useAuthChallenge from '@hooks/useAuthChallenge'
import useAuthProviderOnly from '@hooks/useAuthProviderOnly'
import { ChallengeType } from '@models/AuthChallenge'
import { Link, Modal, Text } from '@nextui-org/react'
import delay from '@shared/Delay'
import { useAppSelector } from '@store/hooks'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export default function CaptchaChallengeModal() {
  const { formatMessage: f } = useIntl()
  const { message } = useAuthChallenge()
  const { authenticate } = useAuthProviderOnly()
  const { error } = useAppSelector((state) => state.authProvider)

  const [isCaptchaLinkOpen, setIsCaptchaLinkOpen] = useState(false)
  const [isWaitingForAuth, setIsWaitingForAuth] = useState(false)

  const openCaptchaLink = () => {
    delay(500).then(() => setIsCaptchaLinkOpen(true))
  }

  const signInAgain = () => {
    setIsWaitingForAuth(true)
    authenticate()
  }

  useEffect(() => {
    if (error !== undefined) {
      console.error('Cannot autheticate with provider', error)
    }
  }, [error])

  return (
    <Modal
      aria-labelledby="modal-title"
      blur
      open={
        Boolean(message.isOpen) &&
        (message.challengeType === ChallengeType.CAPTCHA ||
          message.challengeType === ChallengeType.ALL)
      }
      preventClose
    >
      <Modal.Body className="pt-8">
        <div className="flex flex-col items-center">
          <Image
            className="w-[180px] md:w-[230px] h-auto"
            src={secureLogin}
            alt="picture"
          />
          {message?.location !== undefined && (
            <div className="w-full mt-16 mb-6 flex flex-col items-center">
              {!isCaptchaLinkOpen ? (
                <>
                  <Text className="mb-2">
                    {f({ id: 'Amazon is requesting a captcha' })}
                  </Text>
                  <Link
                    isExternal
                    block
                    href={message.location}
                    target="_blank"
                    className="text-center"
                    onPress={openCaptchaLink}
                  >
                    {`${f({
                      id: 'Click here and solve a captcha first'
                    })}`}
                  </Link>
                </>
              ) : (
                <>
                  <Text className="text-center">
                    {f({
                      id: 'Now you can sign in again with Amazon Flex'
                    })}
                  </Text>
                  <BButton
                    disabled={!isCaptchaLinkOpen}
                    className="w-full mt-4"
                    isLoading={isWaitingForAuth}
                    onClick={signInAgain}
                    text={f({
                      id: 'Sign in'
                    })}
                    type="button"
                  />
                </>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
