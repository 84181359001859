import { AUTH_FAIL_NOTIFICATION_TITLE } from '@constants/index'
import useAuthProviderFailure from '@hooks/useAuthProviderFailure'
import { Alert, AlertTypes } from '@models/dtos/Alert'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

export default function AuthenticationErrorNotification() {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()
  const { errorMessage } = useAuthProviderFailure()

  useEffect(() => {
    if (errorMessage === undefined) return

    dispatch({
      type: 'notifications/add',
      payload: new Alert(
        f({ id: AUTH_FAIL_NOTIFICATION_TITLE }),
        f({ id: errorMessage }),
        AlertTypes.Error
      )
    })
  }, [errorMessage])

  return <></>
}
