import AskCancelAutoRejectOfferModal from './AskCancelAutoRejectOffer/AskCancelAutoRejectOfferModal'
import AuthChallengesModals from './AuthChallengesModals/AuthChallengesModals'
import Navbar from './Navbar'
import AuthenticationErrorNotification from './Notifications/AuthenticationErrorNotification'
import GlobalAlerts from './Notifications/GlobalAlerts'
import styles from './layout.module.css'

export const siteTitle = 'UNLOCKFLEX'

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <AskCancelAutoRejectOfferModal />
      <AuthChallengesModals />
      <AuthenticationErrorNotification />
      <Navbar />
      <GlobalAlerts />
      <div className={`${styles.container} p-4 md:p-12 md:pl-28`}>
        <main className="xl:pl-40 w-full h-full">{children}</main>
      </div>
    </>
  )
}
