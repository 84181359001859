import { useSignalRWsSecure } from '@context/signalr/SignalRWsSecureContext'
import { useCallback, useEffect, useState } from 'react'

export interface UseAuthFailureType {
  errorMessage: string
}

/**
 * Custom hook for handling authentication provider failure.
 *
 * @return {UseAuthFailureType} Object containing the error message
 */
export default function useAuthProviderFailure(): UseAuthFailureType {
  const hubConnectionContext = useSignalRWsSecure()
  const [errorMessage, setErrorMessage] = useState<string>()

  const onAuthProviderFailure = useCallback(() => {
    hubConnectionContext?.on(
      'AuthFailure',
      (_connectionId: string, message: string) => {
        setErrorMessage(message)
      }
    )
  }, [hubConnectionContext])

  useEffect(() => {
    onAuthProviderFailure()
  }, [onAuthProviderFailure])

  return { errorMessage }
}
