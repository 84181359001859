import { StepConditionStrategy } from '@components/ProductTour/Shared/StepConditionStrategy'

export class StepOneCondition extends StepConditionStrategy {
  constructor(step: number, condition: boolean) {
    super()
    const STEP_ONE = 1

    this.id = STEP_ONE
    this.step = step
    this.condition = condition
  }
}
