import { AUTO_CLOSE_NOTIFICATION_DELAY } from '@constants/index'
import { showNotification } from '@mantine/notifications'
import { Alert, AlertStatus, AlertTypes } from '@models/dtos/Alert'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useEffect } from 'react'

export default function GlobalAlerts() {
  const dispatch = useAppDispatch()
  const { alerts } = useAppSelector((state) => state.notifications)

  const onCloseHandler = (alert: Alert) => {
    dispatch({ type: 'notifications/remove', payload: alert })
  }

  useEffect(() => {
    for (const alert of alerts) {
      if (alert.status !== AlertStatus.Pending) continue

      showNotification({
        autoClose: AUTO_CLOSE_NOTIFICATION_DELAY,
        title: alert.title,
        message: alert.text,
        color: alert.type === AlertTypes.Error ? 'red' : 'blue',
        radius: 'lg',
        onClose: () => {
          onCloseHandler(alert)
        }
      })

      alert.status = AlertStatus.Delivered
    }
  }, [alerts])

  return <div></div>
}
