import AvatarMarble from '@components/AvatarMarble'
import ChangeLang from '@components/ChangeLang'
import ChangeTheme from '@components/ChangeTheme/ChangeTheme'
import IconNotification from '@components/IconNotification'
import BIcon from '@components/bIcon'
import { AVATAR_COLORS } from '@constants/colors'
import { Transition } from '@headlessui/react'
import { Session } from '@models/Session'
import { Link } from '@nextui-org/react'
import { ClassNames } from '@shared/index'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef
} from 'react'
import { NotificationType } from '.'

export default function MobileNavbar({
  isCollapsed,
  setIsCollapsed,
  session,
  notifications,
  getInteractions,
  openHandlerNotification,
  onClickHandlerNotification,
  logoutHandle
}: {
  isCollapsed: boolean
  setIsCollapsed: Dispatch<SetStateAction<boolean>>
  session: Session
  notifications: NotificationType[]
  getInteractions?: () => Array<MutableRefObject<any> | undefined>
  openHandlerNotification: (e: any) => void
  onClickHandlerNotification: (notification: NotificationType) => void
  logoutHandle: (e: any) => void
}) {
  const dispatch = useAppDispatch()
  const { routes, activeRouteId } = useAppSelector((state) => state.navigation)

  const refCollapsedMenu = useRef(undefined)

  const changeActiveRoute = (id: number) => {
    setIsCollapsed(true)
    dispatch({ type: 'navigation/changeActiveRoute', payload: id })
  }

  useEffect(() => {
    const listener = (event: any) => {
      if (
        refCollapsedMenu === undefined ||
        refCollapsedMenu.current?.contains(event.target) === true
      ) {
        return
      }

      const interactions = getInteractions()
      for (let i = 0; i < interactions.length; i++) {
        const ref = interactions[i]

        if (
          ref === undefined ||
          ref?.current?.contains(event.target) === true
        ) {
          return
        }
      }

      setIsCollapsed(true)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [refCollapsedMenu, getInteractions, setIsCollapsed])

  return (
    <Transition
      show={!isCollapsed}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0 rotate-[-120deg] scale-50"
      enterTo="opacity-100 rotate-0 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 rotate-0 scale-100 "
      leaveTo="opacity-0 scale-95 "
    >
      <div className="collapsed-menu-overlay bg-neutral-300 bg-opacity-50 dark:bg-deepblue-200 dark:bg-opacity-80">
        <div
          ref={refCollapsedMenu}
          className="collapsed-menu px-4 py-8 bg-neutral-100 dark:bg-deepblue-300 shadow-2xl"
        >
          <div className="px-4 pb-8">
            <div className="w-16 h-16 md:ml-4 mr-2 rounded-full overflow-clip">
              <AvatarMarble
                title={session.name}
                size={64}
                name={session.name}
                colors={AVATAR_COLORS}
              />
            </div>
            <p className="mt-4 text-lg font-bold">{session.name}</p>
          </div>

          <div className="">
            <p className="px-4 pb-4 text-sm opacity-50">CONTENT</p>
            <ul className="menu-content">
              {routes.map((item, index) => (
                <li
                  key={index}
                  className={ClassNames(
                    index === activeRouteId ? 'selected' : '',
                    'p-2 px-4 dark:hover:bg-[#10253e] rounded-2xl cursor-pointer'
                  )}
                  onClick={() => changeActiveRoute(index)}
                >
                  <span className="dark:text-accents-1 hover:no-underline">
                    <BIcon className={'mr-3'} icon={item.icon} />
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-8 px-4">
            <p className="pb-4 text-sm opacity-50">CUSTOMIZATION</p>

            <ul className="">
              <li className="flex items-center pb-1">
                <span className="flex items-center justify-center w-4 h-4 rounded-full  bg-red-100 text-xs">
                  {notifications.length}
                </span>
                <p className="pl-2 pr-4">Notifications</p>
                <IconNotification
                  notifications={notifications}
                  onOpen={openHandlerNotification}
                  onClick={onClickHandlerNotification}
                />
              </li>
              <li className="flex items-center pb-1">
                <p className="pr-4">Select theme</p>
                <ChangeTheme />
              </li>
              <li className="flex items-center">
                <p>Language</p>
                <ChangeLang />
              </li>
            </ul>
          </div>

          <div className="mt-8 px-5">
            <Link color="error" onClick={logoutHandle} href="">
              logout
            </Link>
          </div>
        </div>
      </div>
    </Transition>
  )
}
