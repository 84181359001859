export interface AuthChallengeMessage {
  isOpen?: boolean
  challengeType: ChallengeType
  location: string
}

export enum ChallengeType {
  CAPTCHA = 'CAPTCHA',
  AUTH = 'AUTH',
  ALL = 'ALL'
}
