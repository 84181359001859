import { ReferredTour } from '@components/ProductTour/Referred/ReferredTour'
import ReferredProductTour from '@components/ProductTour/Referred/ReferredTourSteps'
import { Tab } from '@headlessui/react'
import Dashboard from '@pages/dashboard'
import Referred from '@pages/dashboard/referred'
import Settings from '@pages/dashboard/settings'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useCallback } from 'react'
import { ClassNames } from 'shared'
import BIcon from '../bIcon'

export default function SideBar() {
  const dispatch = useAppDispatch()
  const { routes, activeRouteId } = useAppSelector((state) => state.navigation)
  const { activeTours } = useAppSelector((state) => state.productTour)

  const changeActiveRoute = (id: number) => {
    dispatch({ type: 'navigation/changeActiveRoute', payload: id })
  }

  const handleActiveTour = useCallback(
    (tourId: string) => {
      if (tourId === undefined) return false
      return activeTours.get(tourId) === true
    },
    [activeTours]
  )

  return (
    <div className="sidebar">
      <Tab.Group
        vertical
        selectedIndex={activeRouteId}
        onChange={changeActiveRoute}
      >
        <Tab.List className="flex fixed left-0 xl:mt-20 xl:ml-16 xl:w-64 md:w-auto md:ml-4">
          <div className="space-y-7 xl:w-40 hidden md:block">
            {routes.map((item, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  ClassNames(
                    'flex p-3.5 px-5 w-full rounded-[20px] text-left transition-all ease-in-out tap-btn',
                    selected ? 'selected' : 'bg-transparent'
                  )
                }
              >
                <BIcon className={'xl:mr-3'} icon={item.icon} />
                <span className="hidden xl:block">{item.name}</span>
              </Tab>
            ))}
          </div>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <Dashboard handleActiveTour={handleActiveTour} />
          </Tab.Panel>
          {/* <Tab.Panel>
            {handleActiveTour(ScheduleProductTour.tourId) ? (
              <ScheduleTour />
            ) : (
              <Schedule />
            )}
          </Tab.Panel> */}
          <Tab.Panel>
            {handleActiveTour(ReferredProductTour.tourId) ? (
              <ReferredTour />
            ) : (
              <Referred />
            )}
          </Tab.Panel>
          <Tab.Panel>
            <Settings />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
