import BInput from '@components/BInput'
import ListStatus from '@components/Referred/ListStatus'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import BButton from '@components/bButton/BButton'
import SectionWidget from '@components/sectionWidget'
import { blocksIcons } from '@constants/icons'
import { useReferredProductTour } from '@hooks/ProductTourSteps/useReferredProductTour'
import useProductTour, {
  StepConditionCallbackType
} from '@hooks/useProductTour'
import { InvitationDto } from '@models/dtos/InvitationDto'
import FindLast from '@shared/FindLast'
import { FormikHelpers, useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { StepOneCondition } from '../Schedule/StepConditions/StepOneCondition'
import { StepConditionStrategy } from '../Shared/StepConditionStrategy'
import ReferredProductTour from './ReferredTourSteps'
import { StepFourCondition } from './StepConditions/StepFourCondition'
import { StepTwoCondition } from './StepConditions/StepTwoCondition'

export function ReferredTour() {
  const { formatMessage: f } = useIntl()
  const defaultStepStates = useMemo(
    () => ({
      isSubmit: false,
      isCopied: false
    }),
    []
  )

  const [{ isSubmit, isCopied }, setStepStates] = useState(defaultStepStates)

  const [isValidEmail, setIsValidEmail] = useState(false)

  const [stepCondition, setStepCondition] = useState<StepConditionCallbackType>(
    () => {
      return (step) => {
        const strategies: StepConditionStrategy[] = [
          new StepOneCondition(step, isValidEmail),
          new StepTwoCondition(step, isSubmit),
          new StepFourCondition(step, isCopied)
        ]
        const strategy = FindLast(strategies, (x) => x.IsActive())

        // Set states to default
        setStepStates(() => defaultStepStates)

        return strategy
      }
    }
  )
  useEffect(() => {
    setStepCondition(() => {
      return (step) => {
        const strategies: StepConditionStrategy[] = [
          new StepOneCondition(step, isValidEmail),
          new StepTwoCondition(step, isSubmit),
          new StepFourCondition(step, isCopied)
        ]
        const strategy = FindLast(strategies, (x) => x.IsActive())

        // Set states to default
        setStepStates(() => defaultStepStates)

        return strategy
      }
    })
  }, [isCopied, isSubmit, isValidEmail])

  const { next, resetTour, setIsOpen } = useProductTour(
    ReferredProductTour,
    stepCondition
  )
  const { createInvitation, invitations } = useReferredProductTour()

  const refButton = useRef<HTMLButtonElement>(null)
  const initValue: InvitationDto = {
    email: ''
  }
  const referredFormSchema = yup.object({
    email: yup
      .string()
      .email(
        f({
          id: 'Please enter valid email'
        })
      )
      .required(
        f({
          id: 'Invited email is required'
        })
      )
  })
  const isLoading = false

  const handleSubmit = (
    value: InvitationDto,
    actions: FormikHelpers<InvitationDto>
  ) => {
    createInvitation(value)
    setStepStates((prevState) => ({
      ...prevState,
      isSubmit: true
    }))

    // Go next step
    next(true)
    actions.resetForm()
  }

  const handleCopyBtnClick = useCallback(() => {
    setStepStates((prevState) => ({
      ...prevState,
      isCopied: true
    }))

    // Go next step
    next(true)
  }, [next])

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: referredFormSchema,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    if (formik.errors.email !== undefined) {
      console.log('should not pass')
      setIsValidEmail(false)
      return
    }

    if (formik.values.email === '') return

    setIsValidEmail(true)
    console.log('pass')
  }, [formik.errors, formik.values.email])

  useEffect(() => {
    const current = refButton.current

    if (refButton.current !== null) {
      refButton.current.onclick = handleCopyBtnClick
    }

    return () => {
      if (current !== null) {
        current.onclick = null
        refButton.current = current
      }
    }
  }, [refButton, handleCopyBtnClick])

  useEffect(() => {
    resetTour()
    setIsOpen(true)
  }, [])

  return (
    <section id="referred">
      <div className="referred-header mb-[60px]">
        <h1 className="text-xl md:text-3xl">{f({ id: 'Referred panel' })}</h1>
      </div>
      <div className="referred-container md:flex md:flex-wrap md:gap-[30px]">
        <SectionWidget
          title={f({ id: 'Invite friends' })}
          className="md:w-full lg:w-auto mb-0 shadow-xl"
        >
          <div className="w-full flex flex-wrap gap-6">
            <SubsectionWrapper className="w-full lg:w-[480px] min-h-[450px]">
              <div className="mb-4 flex gap-6">
                <p className="font-bold">{f({ id: 'All yours referred' })}</p>
              </div>
              <form onSubmit={formik.handleSubmit} className="w-full">
                <div className="md:flex items-end gap-4 mb-6">
                  <BInput
                    className="input-email mb-4 md:mb-0"
                    fullWidth
                    label={f({ id: 'Invited email' })}
                    name="email"
                    value={formik.values?.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email as string}
                    contentLeft={blocksIcons.mail}
                  />
                  <BButton
                    auto
                    type="submit"
                    className="btn-invite min-w-[130px]"
                    isLoading={isLoading}
                    text={f({ id: 'Make an invitation' })}
                  />
                </div>
                {/* {currentInvitation !== undefined &&
                      currentInvitation.status === InvitationStatus.Pending && (
                        <div className="w-full mb-6">
                          <p className="nextui-input-block-label text-sm pl-1 mb-1.5">{`${f(
                            {
                              id: 'Invitation for'
                            }
                          )} ${String(currentInvitation.email)}`}</p>
                          <Snippet text={invitationUrl} />
                        </div>
                      )} */}
              </form>

              {invitations?.length > 0 && (
                <>
                  <div className="mb-4 flex justify-between gap-6">
                    <p className="font-bold">
                      {f({ id: 'Invitations status' })}
                    </p>
                    <p className="text-sm text-neutral-400">
                      <span className="pr-1">{invitations?.length ?? 0}</span>
                      {invitations?.length > 1
                        ? f({ id: 'invitations' })
                        : f({ id: 'invitation' })}
                    </p>
                  </div>
                  <ListStatus refButton={refButton} invitations={invitations} />
                </>
              )}
            </SubsectionWrapper>
          </div>
        </SectionWidget>
      </div>
    </section>
  )
}
