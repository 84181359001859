import { InvitationDto } from '@models/dtos/InvitationDto'
import { Invitation, InvitationStatus } from '@models/Invitation'
import moment from 'moment'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export interface UseReferredProductTourReturnType {
  invitations: Invitation[]
  hasPressInviteButton: boolean
  hasPressCopy: boolean
  createInvitation: (invitation: InvitationDto) => void
}

export function useReferredProductTour(): UseReferredProductTourReturnType {
  const [hasPressInviteButton, setHasPressInviteButton] = useState(false)
  const [hasPressCopy, setHasPressCopy] = useState(false)
  const [invitations, setInvitations] = useState<Invitation[]>([])

  const createInvitation = (invitation: InvitationDto) => {
    const newInvitation: Invitation = {
      email: invitation.email,
      token: invitation.token,
      status: InvitationStatus.Pending,
      referredBy: '',
      expiration: moment().add(7, 'day').toISOString()
    }

    setHasPressInviteButton(true)
    invitations.push(newInvitation)
    setInvitations(invitations)
  }

  return {
    invitations,
    hasPressInviteButton,
    hasPressCopy,
    createInvitation
  }
}
