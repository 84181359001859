import { ProductTourProvider } from '@components/ProductTour/context/ProductTourContext'
import SideBar from '@components/SideBar/SideBar'
import Layout from '@components/layout'
import SignalRWsSecureProvider from '@context/signalr/SignalRWsSecureContext'
import { getSortedPostsData } from '@lib/posts'
import { GetStaticProps } from 'next'

export default function Home({
  allPostsData
}: {
  allPostsData: Array<{
    date: string
    title: string
    id: string
  }>
}) {
  return (
    <SignalRWsSecureProvider>
      <ProductTourProvider>
        <Layout>
          <SideBar />
        </Layout>
      </ProductTourProvider>
    </SignalRWsSecureProvider>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const allPostsData = getSortedPostsData()
  return {
    props: {
      allPostsData
    }
  }
}
