import { AVATAR_COLORS } from '@constants/colors'
import { Link } from '@nextui-org/react'
import { logout } from '@store/auth/auth.slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { MutableRefObject, useRef, useState } from 'react'
import AvatarMarble from '../AvatarMarble'
import BrandLogo from '../BrandLogo'
import ChangeLang from '../ChangeLang'
import ChangeTheme from '../ChangeTheme/ChangeTheme'
import IconNotification from '../IconNotification'
import MenuToggleButton from '../MenuToggleButton'
import MobileNavbar from './MobileNavbar'

export interface NotificationType {
  uuid: string
  text: string
}

// TODO: Create a model for notification entity
export const initNotifications: NotificationType[] = [
  {
    uuid: '3bc82ef7-1138-4f97-945a-08626a42a648',
    text: 'You are login for the first time'
  },
  {
    uuid: 'dff59ac0-4d80-4b96-85c4-14f3a118e7fe',
    text: '5 Captures missing'
  }
]

export default function Navbar() {
  const dispatch = useAppDispatch()
  const session = useAppSelector((state) => state.auth.session)
  const { isCapturing } = useAppSelector((state) => state.capture)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const [notifications, setNotifications] =
    useState<NotificationType[]>(initNotifications)

  let refMenuToggleButton = useRef(undefined)

  const logoutHandle = (e) => {
    dispatch(logout())
  }

  const openHandlerNotification = (e: any) => {
    console.log('open notifications')
  }

  const toggleCollapseMenu = () => {
    setIsCollapsed(!isCollapsed)
  }

  const onClickHandlerNotification = (notification: NotificationType) => {
    setNotifications(notifications.filter((n) => n.uuid !== notification.uuid))
  }

  const setRef = (ref: MutableRefObject<unknown>) => {
    refMenuToggleButton = ref
  }

  function getInteractions(): Array<MutableRefObject<any>> {
    return [refMenuToggleButton]
  }

  return (
    <div className="navbar flex items-center sticky top-0 mb-2 md:px-16 px-4 py-2 w-full bg-accents-3 dark:bg-deepblue-300 dark:border-b-deepblue-150">
      {/* TODO: change Capture status to other place */}
      {/* <div className="capture-status flex md:block text-xs font-bold">
        <p className="hidden md:block dark:text-gray-100">Capture status:</p>
        <p className="text-right dark:text-gray-50">
          {isCapturing === true ? 'active' : 'inactive'}
          <StatusNotification
            isAlert={isCapturing === false}
            className="ml-2"
          />
        </p>
      </div> */}
      <div className="flex">
        <BrandLogo small />
      </div>
      <>
        <div className="hidden md:flex items-center ml-auto">
          <IconNotification
            notifications={notifications}
            onOpen={openHandlerNotification}
            onClick={onClickHandlerNotification}
          />
          <ChangeTheme className="mx-4" />
          <ChangeLang />

          <p className="text-xs md:text-sm dark:text-gray-50 ml-2 md:ml-4">
            {session.name}
          </p>
          <div className="relative w-8 h-8 ml-2 md:ml-4 mr-2 rounded-full overflow-clip">
            <AvatarMarble
              title={session.name}
              size={32}
              name={session.name}
              colors={AVATAR_COLORS}
            />
          </div>
          <Link color="error" onClick={logoutHandle} href="">
            logout
          </Link>
        </div>

        <div className="md:hidden ml-auto">
          <MenuToggleButton
            setRef={setRef}
            colorCls="bg-black dark:bg-accents-4"
            isCollapsed={isCollapsed}
            onClick={toggleCollapseMenu}
          />

          {/* Mobile menu */}
          <MobileNavbar
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            session={session}
            notifications={notifications}
            getInteractions={getInteractions}
            openHandlerNotification={openHandlerNotification}
            onClickHandlerNotification={onClickHandlerNotification}
            logoutHandle={logoutHandle}
          />
        </div>
      </>
    </div>
  )
}
