import BIcon from '@components/bIcon'
import { blocksIcons } from '@constants/icons'
import StatusNotification from '@components/statusNotification/StatusNotification'
import { ClassNames } from 'shared'
import { Popover, Text } from '@nextui-org/react'
import { MouseEventHandler, useState } from 'react'
import { NotificationType } from './Navbar'

export default function IconNotification({
  className,
  icon,
  notifications,
  onOpen,
  onClick
}: {
  className?: string
  icon?: string
  notifications: NotificationType[]
  onOpen: any
  onClick: (item: NotificationType) => void
}) {
  const [isOpen, setIsOpen] = useState(false)
  const iconSize = 18

  // TODO: loop notifications list and add to a tooltip
  const handleClick = (n: NotificationType) => {
    setIsOpen(false)
    onClick(n)
  }

  return (
    <div className={ClassNames(className, 'icon-notification')}>
      <Popover
        isOpen={isOpen}
        onOpenChange={() => {
          // Don't open if notfications are empty
          if (notifications.length === 0) return

          setIsOpen(!isOpen)

          // Execute when open
          if (!isOpen) {
            onOpen()
          }
        }}
      >
        <Popover.Trigger>
          <div className={`relative w-[18px] h-[18px] cursor-pointer`}>
            <BIcon
              icon={blocksIcons.bell}
              size={iconSize}
              className="absolute top-0 right-0"
            />
            {notifications.length > 0 && (
              <StatusNotification
                isAlert={true}
                className="!absolute top-0 right-0 ml-2"
              />
            )}
          </div>
        </Popover.Trigger>
        <Popover.Content className="p-2">
          <ul>
            {notifications.map((notification, idx) => (
              <li
                key={idx}
                onClick={() => {
                  handleClick(notification)
                }}
                className="p-1 px-3 mb-2 last:mb-0 cursor-pointer rounded-lg bg-deepblue-50/50 transition ease-in-out duration-300 hover:bg-deepblue-50"
              >
                {notification.text}
              </li>
            ))}
          </ul>
        </Popover.Content>
      </Popover>
    </div>
  )
}
