import { API_URL, CANCEL_AUTO_REJECT_OFFER_ENDPOINT } from '@constants'
import { SecureRequestMiddleware } from '@lib/secure_request.middleware'
import { CancelAutoRejectModel } from '@models/CancelAutoRejectModel'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function cancelAutoRejectOfferService(
  token: string,
  data: CancelAutoRejectModel
) {
  const resp = await SecureRequestMiddleware<
    CancelAutoRejectModel,
    AxiosResponse<unknown>
  >(async (config: AxiosRequestConfig, _data: CancelAutoRejectModel) => {
    return await axios.post(
      String(API_URL) + String(CANCEL_AUTO_REJECT_OFFER_ENDPOINT),
      _data,
      config
    )
  }, data)

  if (resp.status === 200) {
    return resp.data
  }

  return undefined
}
