import { useSignalRWsSecure } from '@context/signalr/SignalRWsSecureContext'
import { AuthChallengeMessage, ChallengeType } from '@models/AuthChallenge'
import { VerificationCode } from '@models/dtos/VerificationCode'
import delay from '@shared/Delay'
import { useAppDispatch } from '@store/hooks'
import {
  resendOtpRequest,
  verifyOtpRequest
} from '@store/transactionalApproval/transactionalApproval.slice'
import { useCallback, useEffect, useState } from 'react'

export interface UseAuthChallengeReturnType {
  isVerifyingOtp: boolean
  isResendAvilable: boolean
  message: AuthChallengeMessage
  submit: (value: VerificationCode, actions: unknown) => Promise<void>
  resend: () => void
}

const initialMessage: AuthChallengeMessage = {
  isOpen: false,
  challengeType: ChallengeType.CAPTCHA,
  location: ''
}

export default function useAuthChallenge(): UseAuthChallengeReturnType {
  const dispatch = useAppDispatch()
  const hubConnectionContext = useSignalRWsSecure()
  const [isResendAvilable, setIsResendAvilable] = useState<boolean>(true)
  const [message, setMessage] = useState<AuthChallengeMessage>(initialMessage)
  const [isVerifyingOtp, setIsVerifyingOtp] = useState<boolean>(false)

  const submit = async (
    value: VerificationCode,
    actions: unknown
  ): Promise<void> => {
    setIsVerifyingOtp(true)
    dispatch(verifyOtpRequest(value.otpCode))
  }

  const resend = (): void => {
    if (!isResendAvilable) return
    dispatch(resendOtpRequest())

    setIsResendAvilable(false)
    void resendAvilableCountdown()
  }

  const resendAvilableCountdown = async () => {
    await delay(1000 * 60)
    setIsResendAvilable(true)
  }

  const onAuthChallenge = useCallback(() => {
    hubConnectionContext?.on(
      'AuthChallenge',
      (connectionId: string, msg: AuthChallengeMessage) => {
        setMessage({
          isOpen: msg?.isOpen ?? false,
          challengeType: msg.challengeType,
          location: msg.location
        })
      }
    )
  }, [hubConnectionContext])

  useEffect(() => {
    onAuthChallenge()
  }, [onAuthChallenge])

  return { isVerifyingOtp, isResendAvilable, message, submit, resend }
}
